<template>
    <div class="my-date">
        <!-- 居中 -->
    <van-row type="flex" justify="center" align="bottom">
          <van-col span="6">
              <div class="label" @click="show=true">
                  选择日期
              </div>
          </van-col>
          <van-col span="6" @click="show=true">
              <div class="date-box">{{date}}</div>
          </van-col>
          <van-col span="4" :class="hide?'hide':'show'">
              <van-button style="padding-left: 5px;" @click="clear()" icon="cross" color="#999" size="mini" round/>
          </van-col>
        </van-row>
        <van-notice-bar class="notice" color="#ed6a0c" background="#fff" left-icon="info">
          选择日期后就会查询该日期及之后的订单
        </van-notice-bar>
        <van-popup v-model="show" position="bottom">
            <van-picker
              title="订单日期"
              show-toolbar
              :columns="columns"
              @confirm="onConfirm2"
              @cancel="show = false"
            />
        </van-popup>
        <!-- <van-calendar :min-date="minDate" :max-date="maxDate" v-model="show" @confirm="onConfirm" /> -->
    </div>
</template>

<script>
    import * as dd from 'dingtalk-jsapi'
    var curDate = new Date();
    var dates = [];
    export default{
       data() {
           return {
             date: '',
             show: false,
             hide: true,
             minDate: new Date(curDate.getTime()- 1000* 60*60*24*365), // 一年之前
             maxDate: curDate,
             columns: this.getColumns(),
             index: -1,
           }
         },
         // beforeCreate() {
         //     this.columns = this.getColumns();
         // },
         methods: {
           onConfirm2(value, index){
               this.index = index;
               console.log(value);
               this.date = value;
               this.$emit('change', Math.floor(new Date(dates[index])/1000));
               this.show = false;
               this.hide = false;
           },
           formatDate(date) {
             return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
           },
           onConfirm(date) {
             this.show = false;
             this.date = this.formatDate(date);
             this.hide = false;
             this.$emit('change', Math.floor(date.getTime()/1000));
           },
           clear(){
               var that = this;
               dd.device.notification.confirm({
                   message: "确认清空吗",
                   title: "提示",
                   buttonLabels: ['取消', '确定'],
                   onSuccess : function(result) {
                       if(result.buttonIndex == 1){
                           that.date = '';
                           that.hide = true;
                           that.$emit('change', 0);
                       }
                       
                   }
               });
              
           },
           getColumns(){
               var date = new Date();
               var year = date.getFullYear();
               var month = date.getMonth() + 1;
               var list = [];
               for (var i = 11; i >= 0; i--) {
                   var m = month - i;
                   if(m > 0 ){
                       list.push(`${year}年${m}月`);
                       dates.push(`${year}-${m}`)
                   }else{
                       list.push(`${year - 1}年${12 + m}月`);
                       dates.push(`${year - 1}-${12 + m}`)
                   }
               }
               
               return list;
           }
         }
    }
</script>

<style scoped>
    .my-date{
        font-size: 18px;
    }
    .my-date .label{
        font-weight: bold;
    }
    .my-date .relat{
        position: relative;
        border-bottom: 1px solid #aaa;
    }
    .my-date .date-box{
        text-align: left;
        color: #646465;
        border-bottom: 1px solid #646465;
        min-height: 20px;
        display: inline-block;
    }
    .my-date .hide{
        visibility: hidden;
    }
    .notice{
        font-size: 14px;
    }
    /* .my-date .show{
        visibility:visible;
    } */
</style>