<template>
    <div class="mypage body index">
        <div class="inner-box">
            <!-- 选择日期-->
            <div class="calendar">
                <home-calendar @change="dateChange"></home-calendar>
            </div>
            <!-- 主要内容区-->
            <div class="main">
                <van-row type="flex" gutter="20" justify="center">
                  <van-col span="12" style="text-align: right;">
                      <div class="h3">发动机</div>
                      <div class="kw-item">
                          <div class="item-label">
                              订单数量
                          </div>
                          <div class="item-content">
                              {{dashboard.order_engine_num}}
                          </div>
                      </div>
                      <div class="kw-item">
                          <div class="item-label">
                              生产数量
                          </div>
                          <div class="item-content">
                              {{dashboard.made_engine_num}}
                          </div>
                      </div>
                      <div class="kw-item">
                          <div class="item-label">
                              未交付数量
                          </div>
                          <div class="item-content warn">
                              {{dashboard.undelivery_engine_num}}
                          </div>
                      </div>
                  </van-col>
                  <van-col span="12 left-bord">
                      <div class="h3">发电机组</div>
                      <div class="kw-item">
                          <div class="item-label">
                              订单数量
                          </div>
                          <div class="item-content">
                              {{dashboard.order_genset_num}}
                          </div>
                      </div>
                      <div class="kw-item">
                          <div class="item-label">
                              生产数量
                          </div>
                          <div class="item-content">
                              {{dashboard.made_genset_num}}
                          </div>
                      </div>
                      <div class="kw-item">
                          <div class="item-label">
                              未交付数量
                          </div>
                          <div class="item-content warn">
                              {{dashboard.undelivery_genset_num}}
                          </div>
                      </div>
                  </van-col>
                </van-row>
            </div>
            <!-- <van-grid clickable :column-num="2" class="order-menu">
              <van-grid-item icon="plus" text="创建订单" to="/erp/add-order" />
              <van-grid-item icon="search" text="搜索订单" to="/erp/search-order" />
            </van-grid> -->
            <van-grid class="nav-grid" :gutter="10">
              <van-grid-item :icon="icon1" text="新增订单" to="/erp/add-order"/>
              <van-grid-item :icon="icon2" text="搜索订单" to="/erp/search-order"/>
            </van-grid>
            <!-- <van-grid :gutter="10" :column-num="3">
              <van-grid-item >
                  <van-image src="https://img01.yzcdn.cn/vant/apple-1.jpg" />
              </van-grid-item>
              <van-grid-item >
                  <van-image src="https://img01.yzcdn.cn/vant/apple-1.jpg" />
              </van-grid-item>
            </van-grid> -->
            <!--选项 列表内容-->
            <van-tabs type="card" color="#1989fa" animated>
               <van-tab title="未完成订单">
                   <div class="">
                       <order-item :datalist="unfinishedOrderList"></order-item>
                   </div>
               </van-tab>
               <van-tab title="已完成订单">
                   <order-item :datalist="finishedOrderList"></order-item>
               </van-tab>
             </van-tabs>
        </div>
    </div>
</template>

<script>
    import HomeCalendar from '../../components/HomeCalendar.vue';
    import OrderItem from '../../components/OrderItem.vue';
    import { getErpOrderList } from '../../api/task.js';
    import { toast, checkLogin } from '../../utils/common.js';
    //var icon1 = require('../../assets/images/erp-order-add.png');
    
    
    export default{
        components:{
          HomeCalendar,
          OrderItem
        },
        activated() {
            this.hideTab();
             checkLogin().then(()=> {
                 this.getDataList();
             })
        },
        deactivated() {
            console.log('destroy');
        },
        data() {
            return {
                tabsStyle:{
                    'width':'100%',
                },
                icon1: require('../../assets/images/icon-erp-order-add.png'),
                icon2: require('../../assets/images/icon-erp-order-search.png'),
                // 生产数字 展示
                dashboard:{
                    order_genset_num: 0,// 机组订单数量
                    order_engine_num: 0,// 发动机订单数量
                    made_genset_num: 0,// 生产机组数量
                    made_engine_num: 0,// 生产发动机数量
                    undelivery_genset_num: 0,// 未交付机组数量
                    undelivery_engine_num: 0,// 未交付发动机数量
                },
                // 订单列表
                finishedOrderList: [],
                unfinishedOrderList: [],
                // minDate: new Date(2019, 0, 1)
            }
        },
        methods:{
            dateChange(date){
                console.log(date);
                checkLogin().then(()=> {
                    this.getDataList(date);
                })
                //Notify({ type: 'success', message: date });
            },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.add('tab-hide');
            },
            getDataList(date){
                var query = date || '';
                //console.log(query);
                getErpOrderList(query).then((ret)=>{
                    var list = ret.data;
                    var order_genset_num = 0
                       ,order_engine_num = 0
                       ,made_genset_num = 0 
                       ,made_engine_num = 0
                       ,delivery_genset_num = 0  // 变量是交付的 展示的是未交付的
                       ,delivery_engine_num = 0 // 变量是交付的 展示的是未交付的
                       ,finishedOrderList = []
                       ,unfinishedOrderList = [];
                    for (var i = 0,l = list.length; i < l; i++) {
                        var item = list[i];
                        order_genset_num += item['order_genset_num'];
                        order_engine_num += item['order_engine_num'];
                        made_genset_num += item['made_genset_num'];
                        made_engine_num += item['made_engine_num'];
                        delivery_genset_num += item['delivery_genset_num']; // 已经交付
                        delivery_engine_num += item['delivery_engine_num'];// 已经交付
                        
                        // 判断是否完成 机组 和发动机 已经交付的 = 订单数量
                        if(item.order_genset_num == item.delivery_genset_num
                           && item.order_engine_num == item.delivery_engine_num){
                            finishedOrderList.push(item);   
                        }else{
                            unfinishedOrderList.push(item);
                        }
                    }
                    this.dashboard = {
                        order_genset_num: order_genset_num,// 机组订单数量
                        order_engine_num: order_engine_num,// 发动机订单数量
                        made_genset_num: made_genset_num,// 生产机组数量
                        made_engine_num: made_engine_num,// 生产发动机数量
                        undelivery_genset_num: order_genset_num - delivery_genset_num,// 未交付机组数量
                        undelivery_engine_num: order_engine_num - delivery_engine_num,// 未交付发动机数量
                    };
                    this.finishedOrderList = finishedOrderList;
                    this.unfinishedOrderList = unfinishedOrderList;
                    console.log(ret);
                }).catch((err)=>{
                    toast(err.message ||'获取失败');
                })
            },
            resetData(){
                this.dashboard = {
                    order_genset_num: 0,// 机组订单数量
                    order_engine_num: 0,// 发动机订单数量
                    made_genset_num: 0,// 生产机组数量
                    made_engine_num: 0,// 生产发动机数量
                    undelivery_genset_num: 0,// 未交付机组数量
                    undelivery_engine_num: 0,// 未交付发动机数量
                };
                this.finishedOrderList = [];
                this.unfinishedOrderList = [];
            }
            
        }
    }
</script>

<style scoped>
    .mypage{
        min-height: 100vh;
        background-color: #fff;
    }
    .inner-box{
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .main{
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        font-size: 16px;
        border-radius: 5px;
        /* font-weight: bold; */
        -webkit-box-shadow: 0 0 3px 2px #ddd ;
        box-shadow: 0 0 3px 2px #ddd  ;
    }
    .main .h3{
        font-size: 16px;
        text-align: center;
        margin: 1rem 0;
        font-weight: bold;
    }
    .main .left-bord{
        box-sizing: border-box;
        border-left: 1px solid #f2f2f2;
    }
    .kw-item{
        display: flex;
        margin: 0.8rem 0;
    }
    .kw-item .item-label{
        min-width: 8rem;
        text-align: right;
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .kw-item .item-content{
        font-size: 16px;
        font-weight: bold;
        overflow-wrap:anywhere;
    }
    .warn{
        color: #ee0a24;
    }
    .order-menu{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .nav-grid{
        margin: 5px 0 10px;
    }
</style>